<template>
  <div class="orders">
    <top-bar />
    <page-title title="Мои заказы"
                description="В этом разделе отображаются сформированные вами заказы"
                :image="flower1"
    />
    <n-loader :loading="$var('load')" />

    <div class="container">
      <div v-if="$n.size(orders)" class="sales-list">
        <order-card v-for="order in orders" :key="order.id" :order="order" />
      </div>
      <div v-else-if="!$var('load')">У вас еще нет заказов</div>
    </div>
  </div>
</template>

<script>
import flower1 from 'assets/flower1.png'
import OrderCard from './../components/OrderCard'

export default {
  name: 'Index',
  components: { OrderCard, },
  data() {
    return {
      flower1,
      orders: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.my.orders().with('seller').with('products').then((response) => {
        this.orders = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.orders{
  .sales-list{
    position: relative;
  }
}
</style>
