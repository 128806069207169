<template>
  <div class="order-card">
    <div class="header">
      <div class="status" :class="[$n.orderStatus(order.status).color]">
        {{ $n.orderStatus(order.status).title }}
      </div>
      <div class="date"><span class="light">Дата заказа:</span> {{ $app.date.format(order.createdAt) }}</div>
    </div>
    <n-link :to="{name: 'orders.index', params:{id: order.id}}">
      <div class="card">
        <div class="left">
          <div class="order-images">
            <div v-for="product in order.products" :key="product.id" :class="[{small: order.products.length > 1}]" class="image">
              <div class="image-offset">
                <img :src="product.avatar" alt="">
                <img class="plug" :src="imgMore" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="info">
            <div class="title">Заказ № {{ order.id }}</div>
            <template v-if="fromSeller">
              <div><span class="light">Покупатель:</span> {{ order.fullName }}</div>
              <div><span class="light">Телефон:</span> {{ $n.phoneFormat(order.phone) }}</div>
            </template>
            <template v-else>
              <div><span class="light">Продавец:</span> {{ sellerTitle }}</div>
              <div><span class="light">Телефон:</span> {{ $n.phoneFormat(order.seller.phone) }}</div>
            </template>
            <div><span class="light">Способ оплаты:</span> {{ $n.paymentMethod(order.paymentMethod).title }}</div>
            <div>
              <span class="light">Способ получения:</span>
              {{ $n.deliveryMethod(order.deliveryMethod).title }}
              <template v-if="order.deliveryMethod === 'delivery'">в {{ order.time }}</template>
            </div>
            <div v-if="order.deliveryMethod === 'delivery'">
              <span class="light">Адрес доставки:</span> {{ $n.addressInfo(order) }}
            </div>
          </div>
          <div class="quantity-and-price">
            <div class="quantity">{{ count }} шт</div>
            <div class="price">{{ $n.numberFormat(order.sum) }} ₸</div>
          </div>
        </div>
      </div>
    </n-link>
  </div>
</template>

<script>
import imgMore from 'assets/plug.png'

export default {
  name: 'OrderCard',
  props: {
    order: { type: Object, required: true, },
    fromSeller: { type: Boolean, default: false, },
  },
  data() {
    return {
      imgMore,
    }
  },
  computed: {
    count() {
      return $n.reduce(this.order.products, (result, item) => {
        result += item.orderData.count || 0
        return result
      }, 0)
    },
    sellerTitle() {
      return this.order.sellerType === 'store' ? this.order.seller.title : this.order.seller.fullName
    },
  },
}
</script>

<style scoped lang="scss">
.order-card {
  margin-bottom: 20px;

  a {
    color: inherit;
  }

  .light {
    color: #BABABA;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .status {
      font-size: 12px;
      &.success { color: var(--success); }
      &.danger { color: var(--danger); }
    }

    .date {
      font-size: 12px;
      color: #404040;
    }
  }

  .card {
    display: flex;
    justify-content: space-between;
    background: #FFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 15px;

    .left {
      padding-right: 7px;
      width: 120px;

      .order-images {
        display: flex;
        flex-wrap: wrap;
        height: 115px;
        overflow: hidden;

        .image {
          width: 115px;
          height: 115px;
          text-align: center;

          .image-offset {
            padding: 3px;
            height: 100%;
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }

          &.small {
            width: 57px;
            height: 57px;
            .plug { display: none; }
            &:nth-child(4) {
              .plug { display: block; }
              img { display: none; }
            }
          }
        }
      }
    }

    .right {
      padding-left: 7px;
      width: 100%;

      .title {
        margin-bottom: 6px;
        font-weight: 500;
      }

      .info {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-size: 12px;

        & > div {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .dark {
          margin-left: 3px;
        }
      }
    }

    .quantity-and-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #404040;
      font-size: 14px;

      .price {
        font-weight: bold;
      }
    }
  }
}
</style>
